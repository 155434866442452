<template>
  <div class="align">
    <div class="section-title fade" v-scrollanimation>
      <h2 class="section-header">Fire and Smoke Detection</h2>
    </div>

    <div class="description moveUp intro" v-scrollanimation>
      We prevent disaster in your place unlike others who just detect it. The
      era of ceiling-mounted smoke detectors is over. By the time the smoke
      reaches to the ceiling, it is normally too late. What if the ceiling is
      high? or even no ceiling. We use cutting-edge artificial intelligence and
      image/video processing techniques to detect smoke and fire from streams of
      surveillance cameras. Early fire and smoke detection (FSD) helps our
      customers prevent the massive disaster. FSD does not need internet
      connection, nor external cloud services.
    </div>

    <div class="features-wrap">
      <div class="features-img">
        <div style="position: relative">
          <q-img
            :src="require(`../../assets/FSD/features/background-factory.png`)"
            loading="lazy"
            no-spinner
            class="background-factory"
          />
          <q-img
            :src="require(`../../assets/FSD/features/foreground-smoke.png`)"
            loading="lazy"
            no-spinner
          />
        </div>
      </div>
      <div class="features-smoke left">
        <q-img
          v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl"
          :src="require(`../../assets/FSD/features/smoke-left.png`)"
          loading="lazy"
          class="smoke smoke-left"
          fit="fill"
          no-spinner
        />
      </div>
      <div class="features-smoke right">
        <q-img
          v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl"
          :src="require(`../../assets/FSD/features/smoke-right.png`)"
          loading="lazy"
          class="smoke smoke-right"
          fit="fill"
          no-spinner
        />
      </div>
      <FeaturesList
        :technologies="technologies"
        style="position: relative; top: 0"
      />
    </div>

    <div class="description moveUp demo" v-scrollanimation>
      We can arrange an online demo for you to benchmark our system using your
      sample videos. Interested, please contact us!
    </div>
  </div>
</template>

<script>
import { reactive, defineAsyncComponent } from "vue";
import LoadingComponent from "../LoadingComponent";

const FeaturesList = defineAsyncComponent({
  loader: () => import(/* webpackPrefetch: true */ "../FeaturesList.vue"),
  loadingComponent: LoadingComponent,
});

import {
  mdiTimerSettings,
  mdiMemory,
  mdiLockCheck,
  mdiServerNetwork,
  mdiBellAlert,
  mdiPuzzlePlus,
} from "@quasar/extras/mdi-v6";

export default {
  components: {
    FeaturesList,
  },
  setup() {
    const technologies = reactive([
      {
        title: "Early Real-Time Detection",
        text: "FSD detects fire and smoke from multiple camera streams in real-time. No need for the smoke to reach the old ceiling-mounted smoke detectors.",
        direction: "justify-end",
        order: "order-first",
        imageSrc: require("@/assets/FSD/features/early-detection.jpg"),
        icon: mdiTimerSettings,
      },
      {
        title: "HEOP Camera, No Extra Hardware",
        text: "Our applications are available as apps for your HEOP camera. As Hikvision partner, iThermAI advanced deep-edge algorithms can conveniently be installed on any compatible camera. Our licenses are available on a monthly subscription basis. Feel free to ask for a free demo to see how accurate our products are!",
        direction: "justify-start",
        order: "order-last",
        imageSrc: require("@/assets/FSD/features/heop.jpg"),
        icon: mdiMemory,
      },
      {
        title: "Respecting Privacy",
        text: "For FSD operation, there is no need for an internet connection nor cloud storage. All videos are processed in real-time and never stored on our hardware. The customer is in full control.",
        direction: "justify-end",
        order: "order-first",
        imageSrc: require("@/assets/FSD/features/privacy.jpg"),
        icon: mdiLockCheck,
      },
      {
        title: "Customizable",
        text: "Our algorithms can be customized for your scenario and VMS to ensure the most accuracy and compatibility. You can manipulate the algorithm's sensitivity, smoke color, differentiation of smoke in difficult condition and other advanced custom capabilities upon request.",
        direction: "justify-start",
        order: "order-last",
        imageSrc: require("@/assets/FSD/features/customizable.jpg"),
        icon: mdiServerNetwork,
      },
      {
        title: "HikCentral Pro And Other VMS Integrations",
        text: "FSD sends alarms to the VMS of your choice, allowing you to utilize the full range of alarm systems integrated into the VMS. We are currently supporting HikCentral Pro and Milestone.",
        direction: "justify-end",
        order: "order-first",
        imageSrc: require("@/assets/FSD/features/logos.jpg"),
        icon: mdiBellAlert,
      },
      {
        title: "Seamless Integration With any IP Cameras (Deep Edge)",
        text: "We offer embedded boards that are as small as a mini-computer and can be used as a node to expand your current network of cameras. Each board can monitor at least five camera streams at a time.",
        direction: "justify-start",
        order: "order-last",
        imageSrc: require("@/assets/FSD/features/embedded-board.jpg"),
        icon: mdiPuzzlePlus,
      },
    ]);

    return { technologies };
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/globalStyles.scss";

.intro {
  margin-bottom: 150px;
  margin-top: 20px;
}

.features-wrap {
  position: relative;
  margin: 15px 0;
  padding: 0 0 30vw 0;
  height: max-content;
}

.features-img {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.background-factory {
  position: absolute;
  z-index: -30;
}

.features-smoke {
  position: absolute;
  z-index: -20;
  height: 100%;
}

.left {
  left: 3%;
  width: 75%;
}

.right {
  left: 25%;
  width: 75%;
}

.smoke-left {
  animation: smoke1 3s linear infinite;
  animation-delay: 0.5s;
}

.smoke-right {
  animation: smoke1 4s linear infinite;
  animation-delay: 1.7s;
}

.smoke {
  filter: blur(5px);
  transform-origin: bottom;
  height: 100%;
  opacity: 0.3;
}

.demo {
  margin-top: 50px;
  padding: 40px 20px;
  font-weight: bold;
  border: 8px solid;
  border-radius: 5px;
  border-image: linear-gradient(315deg, #e4d535, #f43e3b) 1;
}

@keyframes smoke1 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scaleY(1);
  }

  25% {
    filter: blur(2px);
    transform: translateY(-10px) scaleY(1.01);
  }

  50% {
    filter: blur(4px);
    transform: translateY(-20px) scaleY(1.02);
  }

  75% {
    filter: blur(6px);
    transform: translateY(-30px) scaleY(1.03);
  }

  100% {
    filter: blur(8px);
    transform: translateY(-40px) scaleY(1.04);
  }
}

@media (max-width: 600px) {
  .intro {
    margin-bottom: 50px;
    text-align: center;
  }
  .demo {
    text-align: center;
  }
  .smoke {
    display: none;
  }
}
</style>
